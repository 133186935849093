import React, { useState, useEffect, Suspense, lazy } from "react";
import { HashRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Header = lazy(() => import("./components/Header/Header"));
const Footer = lazy(() => import("./components/Footer/Footer"));
const FloatingButton = lazy(() => import("./components/FloatingButton/FloatingButton"));
const Error404Page = lazy(() => import("./components/Error404Page/Error404Page"));

const IndexPage = lazy(() => import("./pages/IndexPage/IndexPage"));
const WanjinshiMarathon = lazy(()=> import("./pages/WanjinshiMarathon/WanjinshiMarathon"))
const NagoyaMarathon = lazy(() => import("./pages/NagoyaMarathon/NagoyaMarathon"));
const Scbmarathon = lazy(() => import("./pages/Scbmarathon/Scbmarathon"));
const TaiteiMarathon = lazy(() => import("./pages/TaiteiMarathonTimer/TaiteiMarathon"));
const ExpoCupon = lazy(() => import("./pages/ExpoCupon/ExpoCupon"));
const AboutMaoMao = lazy(() => import("./pages/AboutMaoMao/AboutMaoMao"));
const AboutMarathonTimer = lazy(() => import("./pages/AboutMarathonTimer/AboutMarathonTimer"));

function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    return (
        <Router>
            <Suspense fallback={<div className=""></div>}>
                <div className="App">
                    <div className="top-page">
                        <Header />
                        <main>
                            <Routes>
                                <Route path="/" element={<IndexPage />} />
                                <Route path="/WanjinshiMarathon" element={<WanjinshiMarathon />} />
                                <Route path="/NagoyaMarathon" element={<NagoyaMarathon />} />
                                <Route path="/Scbmarathon" element={<Scbmarathon />} />
                                <Route path="/TaiteiMarathon" element={<TaiteiMarathon />} />
                                <Route path="/AboutMarathonTimer" element={<AboutMarathonTimer />} />
                                <Route path="/ExpoCupon" element={<ExpoCupon />} />
                                <Route path="/AboutMaoMao" element={<AboutMaoMao />} />
                                <Route path="*" element={<Error404Page />} />
                            </Routes>
                            <ScrollToTop />
                        </main>
                        <Footer />
                        <FloatingButton />
                    </div>
                </div>
            </Suspense>
        </Router>
    );
}

export default App;
